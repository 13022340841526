import KToast from '@/components/Toast'
import EventBus from '@/utils/event-bus'

export default {
  install(Vue) {
    Vue.component(KToast.name, KToast)
    const toast = {
      open(options) {
        EventBus.$emit('showToastNotification', options)
      },
      close() {
        EventBus.$emit('closeToastNotification')
      }
    }
    Vue.prototype.$toast = toast
  }
}
