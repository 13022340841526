import api from '@/utils/api'

export default {
  fetchSopApplication: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      api
        .get('second-offer-price/sop-record', { params: payload })
        .then(({ data }) => {
          commit('updateSopApplication', data)
          resolve(data)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  }
}
