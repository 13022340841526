const ResetPassword = () => import(/* webpackChunkName: "reset-password" */ '@/views/Auth/ResetPassword')

export default {
  path: '/reset-password',
  name: 'reset-password',
  component: ResetPassword,
  meta: {
    auth: true,
    password: true
  }
}
