import api from '@/utils/api'

export default {
  async verifyPhoneNumber(_, data) {
    try {
      const response = await api.post('applications/verify-phone-number', data)
      if (response) {
        return Promise.resolve(response)
      }
    } catch (err) {
      return Promise.reject(err)
    }
  }
}
