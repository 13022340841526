export default {
  updateApplications: (state, payload) => {
    state.allApps = payload
  },
  updateApplicationsPagination: (state, payload) => {
    state.pagination = payload
  },
  updateCurrentApplication: (state, payload) => {
    state.currentApplication = payload
  },
  updateStatusAnalytics: (state, payload) => {
    state.allStatuses = payload
  },
  updateSaveChangesPrompt: (state, payload) => {
    state.saveChangesPrompt = payload
  },
  updateResaleCertificates: (state, payload) => {
    state.resaleCertificates = payload
  },
  updateApplicationProductFormData: (state, payload) => {
    state.applicationProductFormData = payload
  },
  updateApplicationFormDeliveryErrorMessage: (state, payload) => {
    state.applicationFormDeliveryErrorMessage = payload
  },
  updateApplicationDeletedProductFormData: (state, payload) => {
    state.applicationDeletedProductFormData = payload
  },
  updateApplicationDeliveryFeeFormData: (state, payload) => {
    state.applicationDeliveryFeeFormData = payload
  },
  updateApplicationFormDisplayTotalLow: (state, payload) => {
    state.applicationFormDisplayTotalLow = payload
  },
  updateCanUserExtendEpo: (state, payload) => {
    state.canUserExtendEpo = payload
  },
  updateEpoExtensionData: (state, payload) => {
    state.epoExtensionData = payload
  }
}
