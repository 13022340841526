export default {
  UpdateApplicationEnvironment({ commit }) {
    const host = window.location.hostname
    let env = ''
    switch (host) {
      case 'localhost':
        env = 'local'
        break
      case 'staging-merchant.kafene.com':
        env = 'staging'
        break
      case 'uat-merchant.kafene.com':
        env = 'uat'
        break
      case 'dev-merchant.kafene.com':
        env = 'development'
        break
      case 'merchant.kafene.com':
        env = 'production'
        break
      default:
        env = host
        break
    }

    commit('SET_APP_ENVIRONMENT', env)
  }
}
