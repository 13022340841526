import KPrivacyPolicy from '@/components/PrivacyPolicy'
import KTermsAndConditions from '@/components/TermsAndConditions'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'KFooter',
  components: {
    KTermsAndConditions,
    KPrivacyPolicy
  },
  data: () => ({
    showPrivacyModal: false,
    showTermsModal: false,
    appVersionNumber: null,
    version: null
  }),
  computed: {
    versionNumber() {
      return process.env.VUE_APP_VERSION || '2.0.0' // Get version number from package.json see vue.config.js
    },
    ...mapGetters({
      supportData: 'getSupportData',
      isLoggedIn: 'auth/getAuthState'
    }),
    merchantSupport() {
      return Object.values(this.supportData).find((val) => val.id === 100)
    }
  },
  methods: {
    ...mapActions({
      fetchSupportDetails: 'fetchSupportDetails',
      fetchAppVersion: 'applications/fetchAppVersion'
    })
  },
  async created() {
    // this.appVersionNumber = await this.fetchAppVersion();
    this.version = JSON.stringify(require('../../../package.json').version)
  },
  async mounted() {
    const { isLoggedIn } = this
    try {
      if (isLoggedIn) {
        await this.fetchSupportDetails()
      }
    } catch (error) {
      this.isLoading = false
      this.$toast.open({
        message: error || 'Unable to fetch merchant support details',
        type: 'error',
        icon: 'close'
      })
      return error
    }
  },
  watch: {
    isLoggedIn(val) {
      if (val) {
        this.fetchSupportDetails()
      }
    },
    'supportData.storeSupport.agent_type': {
      handler(val) {
        if (val === 'Support') this.supportData.storeSupport.agent_type = 'Merchant'
      }
    }
  }
}
