<template>
  <div class="learning-bar">
    <div class="container container--wide">
      <div class="learning-bar__grid">
        <div class="learning-bar__item">
          <div class="learning-bar__media">
            <k-icon icon-name="warning-circle" color="white" font-icon-size="lg" />

            <p>
              You’re in
              <k-tooltip
                class="learning-bar__tooltip"
                position="bottom"
                message="The Learning Portal is an interactive environment where you can experiment with and familiarize yourself with the Kafene Merchant Dashboard."
              >
                LEARNING
              </k-tooltip>
              mode
            </p>
          </div>
        </div>

        <div
          class="learning-bar__item learning-bar__item--end"
          :class="{ 'learning-bar__item--hidden': appLoadingStatus || !showAutofill }"
        >
          <k-button size="small" :disabled="cardStep" @click="autofillData = !autofillData">
            {{ !autofillData || cardStep ? 'Autofill' : 'Clear' }}
          </k-button>
        </div>

        <div v-if="cardStep" class="learning-bar__item learning-bar__item--full">
          <p>
            We're sorry, we cannot prefill card details. You can use the following: Number: 4242424242424242, CVC: 424,
            Expiration: 4/42
          </p>
        </div>

        <div v-if="alternateSigning" class="learning-bar__item learning-bar__item--full">
          <p>
            Typically, you will have to call the merchant support line for the contract code but in the Learning Portal,
            you can use 123456
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KButton from '@/components/Button'
import KIcon from '@/components/Icon'
import KTooltip from '@/components/Tooltip'
import { normalModeHostname } from '@/utils/learning-platform'
import { mapGetters } from 'vuex'

export default {
  components: {
    KButton,
    KIcon,
    KTooltip
  },
  computed: {
    ...mapGetters({
      cardStep: 'getCardStep',
      alternateSigning: 'getAlternateSigning',
      currentApplication: 'applications/getCurrentApplication',
      appLoadingStatus: 'getAppLoadingStatus'
    }),
    showAutofill() {
      if (!this.appLoadingStatus) {
        if (this.$route.meta.checkAppStatus) {
          return this.$route.meta.showAutofill && this.currentAppStatus === 'Enter Merchandise Details'
        }

        return this.$route.meta.showAutofill
      }
      return false
    },
    currentAppStatus() {
      const { application } = this.currentApplication
      return application ? application.application_status : ''
    },
    autofillData: {
      get() {
        return this.$store.getters.getFormAutofill
      },
      set(value) {
        this.$store.commit('setFormAutofill', value)
      }
    },
    normalUrl() {
      return `//${normalModeHostname}`
    }
  },
  methods: {
    closeLearning() {
      window.location.href = this.normalUrl
    }
  }
}
</script>

<style lang="scss">
.learning-bar {
  background-color: $dark;
  color: $white;
  padding: 12px 0;

  p {
    color: $white;
  }

  // equal to navbar
  .container {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }

  &__grid {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin: -8px;
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 0 0 50%;
    padding: 8px;
    text-align: center;

    &:first-child {
      text-align: left;
    }

    &--end {
      justify-content: flex-end;
      text-align: right;
    }

    &--hidden {
      visibility: hidden;
    }

    &--full {
      flex: 0 0 100%;
      justify-content: center;
    }
  }

  &__tooltip {
    color: inherit;
    padding: 0;
    border-bottom: 1px dashed rgba($white, 0.64);

    &:hover {
      cursor: auto;
    }
  }

  &__media {
    display: flex;
    flex-direction: row;
    align-items: center;

    > * {
      flex: 0 0 auto;
    }

    & > * + * {
      margin-left: 10px;
    }
  }
}
</style>
