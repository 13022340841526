import api from '@/utils/api'

export default {
  updateShowLogoutModal: ({ commit }, payload) => {
    commit('SET_UPDATE_LOGOUT_MODAL', payload)
  },
  updateShowAuthorizationModal: ({ commit }, payload) => {
    commit('SET_UPDATE_AUTHORIZATION_MODAL', payload)
  },
  updateAuthorizationStatus: ({ commit }, payload) => {
    commit('SET_UPDATE_AUTHORIZATION_STATUS', payload)
  },
  updateAccessStatus: ({ commit }, payload) => {
    commit('SET_UPDATE_ACCESS_STATUS', payload)
  },
  updateMerchantPhoneNumber: ({ commit }, payload) => {
    commit('SET_UPDATE_PHONE_NUMBER', payload)
  },
  updateSelectedRowDropDownState: ({ commit }, payload) => {
    commit('SET_SELECTED_ROW_DROPDOWN_STATE', payload)
  },
  setSurvicateDetails: ({ commit }, payload) => {
    commit('SET_SURVICATE_DETAILS', payload)
  },
  getSecureAccessStatus: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      api
        .post('merchants/secure-access-status', payload)
        .then(({ data: { data } }) => {
          dispatch('updateAccessStatus', data.data.secure_access)
          dispatch('updateMerchantPhoneNumber', data.data.phone_number)
          resolve(data)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  },
  // eslint-disable-next-line
  requestMerchantOTP: ({ state }, payload) => {
    return new Promise((resolve, reject) => {
      api
        .post('merchants/request-otp', payload)
        .then(({ data }) => {
          resolve(data.data)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  },
  verifyMerchantOTP: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      api
        .post('merchants/verify-otp', payload)
        .then(({ data }) => {
          dispatch('updateAuthorizationStatus', true)
          dispatch('updateShowAuthorizationModal', false)
          resolve(data.data)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  }
}
