import * as rules from 'vee-validate/dist/rules'
import { extend, localize } from 'vee-validate'
import en from 'vee-validate/dist/locale/en.json'
import { required, min, max } from 'vee-validate/dist/rules'
import { Validation, validatePhoneNumber, validateRoutingNumber } from '@kafene_team/validation.kafene.com'
import { CHIME_ROUTING_NUMBERS } from '@/constants'

localize({
  en: {
    messages: {
      routingAndAccountMatch: 'Routing number and account number cannot be identical',
      chimeBankAccount:
        'To continue with a Chime bank account, please click the button below: "Safely Connect with Stripe"',
      poBox: 'PO Boxes are not accepted'
    }
  },
  es: {
    messages: {
      routingAndAccountMatch: 'El número de ruta y el número de cuenta no pueden ser idénticos',
      chimeBankAccount:
        'Para continuar con una cuenta de banco Chime, por favor haga clic en el botón de abajo: "Safely Connect with Stripe"',
      poBox: 'No aceptamos “Apartado Postal” (P.O. Box)'
    }
  }
})

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: en.messages[rule] // assign message
  })
})

extend('required', {
  ...required,
  params: ['errorMessage'],
  message: (fieldName, { errorMessage }) => {
    return errorMessage || `${fieldName} is required.`
  }
})

extend('min', {
  ...min,
  params: ['length', 'errorMessage'],
  validate(value, { length, errorMessage }) {
    const valid = min.validate(value, { length })
    if (!valid) {
      return errorMessage
    }
    return true
  }
})

extend('max', {
  ...max,
  params: ['length', 'errorMessage'],
  validate(value, { length, errorMessage }) {
    const valid = max.validate(value, { length })
    if (!valid) {
      return errorMessage
    }
    return true
  }
})

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: 'Password confirmation does not match'
})

extend('routing', {
  params: ['errorMessage', 'userLanguage', 'isChimeEnabled'],
  validate(value, { errorMessage, userLanguage, isChimeEnabled }) {
    const valid = min.validate(value, { length: 9 })
    if (!valid) return errorMessage

    if (userLanguage === 'Spanish') Validation.setLanguage('es')
    const validationMessage = validateRoutingNumber(value, { isChimeEnabled })
    if (validationMessage) return validationMessage

    return true
  }
})

extend('chimeBankAccount', {
  validate(value) {
    return !CHIME_ROUTING_NUMBERS.includes(value)
  }
})

extend('name', {
  validate(value) {
    return !value.includes(' and ')
  },
  message: 'Applications are individual. Please only enter 1 person’s name'
})

extend('ssnMatch', {
  params: ['target', 'errorMessage'],
  validate(value, { target, errorMessage }) {
    if (value === target) {
      return true
    }
    return errorMessage
  }
})

extend('routingAndAccountMatch', {
  params: ['otherValue'],
  validate(value, { otherValue }) {
    return value !== otherValue
  }
})

extend('phone', {
  params: ['errorMessage'],
  validate(value, { errorMessage }) {
    const valid = min.validate(value, { length: 10 })

    if (!valid) {
      return errorMessage
    }

    const phoneNumberValidation = validatePhoneNumber(value, {
      allowedCountries: ['US', 'CA', 'BS', 'PR']
    })
    if (phoneNumberValidation) {
      return true //TODO: part of KFN-7131. Blocked until bussiness decides if we are ok with this kind of validation
    }

    return true
  }
})

extend('poBox', {
  validate(value) {
    const poBox =
      // eslint-disable-next-line
      /^ *((#\d+)|((box|bin)[-. \/\\]?\d+)|(.*p[ \.]? ?(o|0)[-. \/\\]? *-?((box|bin)|b|(#|num)?\d+))|(p(ost)? *(o(ff(ice)?)?)? *((box|bin)|b)? *\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(number|num|#)? *\d+|(num|number|#) *\d+)/i
    const check = poBox.test(value)
    if (check) {
      return false
    }
    return true
  }
})
