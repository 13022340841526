const TeamPerformance = () => import('@/views/Merchant/Reports/TeamPerformance')

export default {
  path: '/reports/team-performance',
  name: 'team-performance',
  component: TeamPerformance,
  meta: {
    title: 'Kafene | Team Performance'
  }
}
