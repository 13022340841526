import store from '@/store'
import { FEATURE_FLAGS } from '@/constants'

const Leads = () => import('@/views/Merchant/Leads')

export default {
  path: '/leads',
  name: 'leads',
  component: Leads,
  meta: {
    title: 'Kafene | Leads'
  },
  beforeEnter: async (to, from, next) => {
    const isMerchantEligable = store.getters['features/getFlag'](FEATURE_FLAGS.LEADS)
    if (isMerchantEligable) {
      next()
    } else {
      next('/')
    }
  }
}
