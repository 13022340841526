import api from '@/utils/api'
export default {
  fetchApplications: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      api
        .post('applications/all/merchant', data)
        .then(({ data: response }) => {
          const {
            data: {
              applications_data: { applications, current_page, item_count, page_count, status_counts }
            }
          } = response
          commit('updateApplications', applications)
          commit('updateApplicationsPagination', {
            current_page,
            item_count,
            page_count
          })
          commit('updateStatusAnalytics', status_counts)
          resolve(applications)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  },
  fetchSingleApplication: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      api
        .get(`applications/${data}`)
        .then(({ data }) => {
          const { application_data: application } = data.data
          commit('updateCurrentApplication', application)
          resolve(application)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  },
  fetchAppVersion: () => {
    return new Promise((resolve, reject) => {
      api
        .get('/version/get-version')
        .then(({ data }) => {
          const { merchant_dashboard_version } = data.data.data
          const { current_version } = merchant_dashboard_version
          resolve(current_version)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
