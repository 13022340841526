import api from '@/utils/api'

export default {
  // eslint-disable-next-line
  async getLeadsByMerchantId({ commit }, merchantId) {
    try {
      const response = await api.get(`leads/${merchantId}`)
      const leads = response.data.data
      return leads
    } catch (err) {
      throw new Error(err.response.data.message)
    }
  },
  // eslint-disable-next-line
  async saveLeadNotes({ commit }, payload) {
    try {
      return await api.post(`leads/notes`, payload)
    } catch (err) {
      throw new Error(err.response.data.message)
    }
  },
  // eslint-disable-next-line
  async deleteLeadNotes({ commit }, applicantId) {
    try {
      return await api.delete(`leads/notes/${applicantId}`)
    } catch (err) {
      throw new Error(err.response.data.message)
    }
  }
}
