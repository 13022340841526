/* eslint-disable no-unused-vars */
import api from '@/utils/api'

export default {
  fetchStates: ({ state, commit }, payload) => {
    return new Promise((resolve, reject) => {
      api
        .post('merchants/get-available-states', payload)
        .then(({ data }) => {
          commit('updateAvailableStates', data.data.data)
          resolve(data.data.data)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  },
  fetchPricingTiers: ({ state }) => {
    return new Promise((resolve, reject) => {
      api
        .get('applications/get-pricing-tiers')
        .then(({ data }) => {
          resolve(data.data.data)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  },
  calculateEstimate: ({ state }, payload) => {
    return new Promise((resolve, reject) => {
      api
        .post('applications/calculate-estimate', payload)
        .then(({ data }) => {
          resolve(data.data.data)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  }
}
