import api from '@/utils/api'

// eslint-disable-next-line
export const checkIfUserExists = ({ state }, payload) => {
  return new Promise((resolve, reject) => {
    api
      .get(`user/user_email?email=${encodeURIComponent(payload)}`)
      .then(({ data }) => {
        const { register_data } = data.data
        resolve(register_data || null)
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.message)
        } else if (err.request) {
          reject(err.request)
        } else {
          reject(err.message)
        }
        reject(err)
      })
  })
}

// eslint-disable-next-line
export const updateUser = ({ state }, payload) => {
  return new Promise((resolve, reject) => {
    api
      .post('user/update', payload)
      .then(({ data }) => {
        resolve(data.data)
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.message)
        } else if (err.request) {
          reject(err.request)
        } else {
          reject(err.message)
        }
        reject(err)
      })
  })
}

// eslint-disable-next-line
export const createAccount = ({ state }, payload) => {
  return new Promise((resolve, reject) => {
    api
      .post('auth/register', payload)
      .then(({ data }) => {
        resolve(data.data)
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.message)
        } else if (err.request) {
          reject(err.request)
        } else {
          reject(err.message)
        }
        reject(err)
      })
  })
}

// eslint-disable-next-line
export const userAccountConfirmation = ({ state }, payload) => {
  return new Promise((resolve, reject) => {
    api
      .post('auth/account-confirmation', payload)
      .then(({ data }) => {
        resolve(data.data)
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.message)
        } else if (err.request) {
          reject(err.request)
        } else {
          reject(err.message)
        }
        reject(err)
      })
  })
}

// eslint-disable-next-line
export const checkUserPhone = ({ state }, payload) => {
  return new Promise((resolve, reject) => {
    api
      .put('user/phone-number', payload)
      .then(({ data }) => {
        resolve(data.data)
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.message)
        } else if (err.request) {
          reject(err.request)
        } else {
          reject(err.message)
        }
        reject(err)
      })
  })
}
// eslint-disable-next-line
export const resubscribeEmail = ({ state }, payload) => {
  return new Promise((resolve, reject) => {
    api
      .post('resubscribe-email', payload)
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.message)
        } else if (err.request) {
          reject(err.request)
        } else {
          reject(err.message)
        }
        reject(err)
      })
  })
}
