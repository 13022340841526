import Vue from 'vue'
import KButton from '@/components/Button'
import KIcon from '@/components/Icon'
import { KTextInput, KNumericInput, KMoneyInput, KDateInput } from '@/components/Input'

Vue.component('KButton', KButton)
Vue.component('KIcon', KIcon)
Vue.component('KTextInput', KTextInput)
Vue.component('KNumericInput', KNumericInput)
Vue.component('KMoneyInput', KMoneyInput)
Vue.component('KDateInput', KDateInput)
