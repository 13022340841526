import api from '@/utils/api'
export default {
  fetchStores: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      api
        .post('merchants/stores', data)
        .then(({ data }) => {
          const {
            data: { stores_data: stores }
          } = data
          commit('updateStores', stores)
          resolve(stores)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  },
  fetchEmployees: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      api
        .post('merchants/employees', data)
        .then(({ data }) => {
          const {
            data: { employees_data }
          } = data
          commit('updateEmployees', employees_data)
          resolve(employees_data)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  },
  fetchAllEmployees: (store, data) => {
    return new Promise((resolve, reject) => {
      api
        .post('merchants/employees/merchant', data)
        .then(({ data }) => {
          const {
            data: { employees_data }
          } = data
          resolve(employees_data)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  },
  sendSMS: (store, data) => {
    return new Promise((resolve, reject) => {
      api
        .post('merchants/send-sms', data)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  },
  // eslint-disable-next-line
  fetchPermissions: ({ commit }) => {
    return new Promise((resolve, reject) => {
      api
        .get('merchants/permissions')
        .then(({ data }) => {
          const {
            data: { permissions_data }
          } = data
          resolve(permissions_data)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  },
  resendInvitation: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      api
        .post('merchants/resend-invitation', payload)
        .then(async ({ data }) => {
          await dispatch('fetchAllEmployees')
          resolve(data)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  },
  addEmployee: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      api
        .post('merchants/employee', payload)
        .then(async ({ data }) => {
          await dispatch('fetchAllEmployees')
          resolve(data)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  },
  editEmployee: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      api
        .put('merchants/employees', payload)
        .then(async ({ data }) => {
          await dispatch('fetchAllEmployees')
          resolve(data)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  },
  fetchTextToApplyCodes: (options, payload) => {
    return new Promise((resolve, reject) => {
      // payload options {}, {merchant_id: <>}, {store_id: <>}
      api
        .post('merchants/apply-code', payload)
        .then(({ data }) => {
          const response = data.data.data
          resolve(response)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  },
  fetchStoreReport: (options, data) => {
    return new Promise((resolve, reject) => {
      api
        .post('/merchants/report', data)
        .then(({ data }) => {
          resolve(data.data.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateEmployee: (options, payload) => {
    return new Promise((resolve, reject) => {
      api
        .put('applications/update-employee', payload)
        .then(async ({ data }) => {
          resolve(data)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  }
}
