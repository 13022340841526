const ForgotPassword = () => import(/* webpackChunkName: "forgot-password" */ '@/views/Auth/ForgotPassword')

export default {
  path: '/forgot-password',
  name: 'forgot-password',
  component: ForgotPassword,
  meta: {
    auth: true,
    password: true,
    title: 'Kafene | Password Reset'
  }
}
