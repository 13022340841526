import KIcon from '@/components/Icon'
import EventBus from '@/utils/event-bus'

export default {
  name: 'KToastList',
  components: {
    KIcon
  },
  data: () => ({
    notifications: []
  }),
  created() {
    EventBus.$on('showToastNotificationListAdd', (options) => {
      const { message, type, icon, html } = options
      window.FS.event('Toasted', {
        message,
        type,
        icon
      })
      this.notifications.push({
        message: message || 'An error occurred while performing action',
        type: type ? type : this.type,
        icon: icon ? icon : this.icon,
        html: html ? true : false
      })
    })
  },
  methods: {
    removeToast(notificationsIndex) {
      this.notifications.splice(notificationsIndex, 1)
    }
  }
}
