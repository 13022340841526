import api from '@/utils/api'
export const forgotPassword = ({ dispatch }, data) => {
  return new Promise((resolve, reject) => {
    api
      .post('auth/reset-password', data)
      .then(({ data }) => {
        resolve(data.data.message)
      })
      .catch((err) => {
        dispatch('logout')
        if (err.response) {
          reject(err.response.data.message)
        } else if (err.request) {
          reject(err.request)
        } else {
          reject(err.message)
        }
        reject(err)
      })
  })
}

export const verifyToken = ({ dispatch }, data) => {
  return new Promise((resolve, reject) => {
    api
      .get(`auth/reset-password-confirmation?token=${data}`)
      .then(({ data }) => {
        resolve(data.data.message)
      })
      .catch((err) => {
        dispatch('logout')
        if (err.response) {
          reject(err.response.data.message)
        } else if (err.request) {
          reject(err.request)
        } else {
          reject(err.message)
        }
        reject(err)
      })
  })
}

export const changePassword = ({ dispatch }, data) => {
  return new Promise((resolve, reject) => {
    api
      .post('auth/change-password', data)
      .then(({ data }) => {
        dispatch('logout')
        resolve(data.data.message)
      })
      .catch((err) => {
        dispatch('logout')
        if (err.response) {
          reject(err.response.data.message)
        } else if (err.request) {
          reject(err.request)
        } else {
          reject(err.message)
        }
        reject(err)
      })
  })
}
