import KIcon from '@/components/Icon'
export default {
  name: 'KTooltip',
  components: {
    KIcon
  },
  props: {
    message: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: false
    },
    isWhite: {
      type: Boolean,
      required: false
    },
    iconColor: {
      type: String,
      default: 'white',
      required: false
    },
    iconBackgroundColor: {
      type: String,
      default: 'light-grey',
      required: false
    },
    position: {
      type: String,
      default: 'top'
    }
  }
}
