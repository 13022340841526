const ApplicationLinks = () => import(/* webpackChunkName: "resources" */ '@/views/Merchant/Resources/Links')

export default {
  path: '/resources/application-links',
  name: 'links',
  component: ApplicationLinks,
  meta: {
    title: 'Kafene | Links'
  }
}
