const SingleApplication = () => import(/* webpackChunkName: "applications" */ '@/views/Merchant/Applications/Single')

export default {
  path: '/applications/:id',
  name: 'single-application',
  component: SingleApplication,
  meta: {
    showAutofill: true,
    checkAppStatus: true
  }
}
