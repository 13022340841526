import api from '@/utils/api'

export default {
  // eslint-disable-next-line
  canUserExtendEpo: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      api
        .get(`applications/${payload.application_id}/epo/can-extend`)
        .then(({ data }) => {
          commit('updateCanUserExtendEpo', data.canExtendEpo)
          resolve(data)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  },
  getPotentialEpoPlans: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      api
        .get(`applications/${payload.application_id}/epo/potential-pricing`)
        .then(({ data }) => {
          commit('updateEpoExtensionData', data.epoPlans)
          resolve(data)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  }
}
