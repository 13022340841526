const ReportsPage = () => import('@/views/Merchant/Reports/v2')

export default {
  path: '/reports/funding',
  name: 'reports-funding',
  component: ReportsPage,
  meta: {
    title: 'Kafene | Funding'
  }
}
