import icons from '@/data/icons.json'
export default {
  name: 'KIcon',
  props: {
    iconName: {
      type: String,
      required: true
    },
    fontSize: {
      type: Number,
      default: 16
    },
    color: {
      type: String,
      default: 'dark'
    },
    spin: {
      type: Boolean,
      default: false
    },
    background: {
      type: String,
      default: 'transparent'
    },
    regular: {
      type: Boolean,
      default: false
    },
    marginTop: {
      type: Number,
      default: 0
    },
    fontIconSize: {
      type: String
    },
    button: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    icons: icons
  }),
  computed: {
    icon() {
      let iconValue = this.icons.find((icon) => icon.name === this.iconName)
      return iconValue && iconValue.value
    }
  },
  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
