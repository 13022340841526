const Login = () => import(/* webpackChunkName: "login" */ '@/views/Auth/Login')

export default {
  path: '/login',
  name: 'login',
  component: Login,
  meta: {
    auth: true,
    title: 'Kafene | Merchant Login'
  }
}
