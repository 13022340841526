export default {
  getStores: (state) => state.stores,
  getSelectedStore: (state) => state.selectedStore,
  getEmployees: (state) => state.employees,
  getTextToApplyStores: (state) => {
    const matchingStores = {}
    state.stores.forEach((s) => {
      if (s.online_is_active) {
        matchingStores[s.id] = s
      }
    })
    return matchingStores
  },
  getProductTypeIsLoan: (state) => {
    return state.productTypeIsLoan
  }
}
