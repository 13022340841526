import KModal from '@/components/Modal'
export default {
  name: 'KTermsAndConditions',
  components: { KModal },
  props: {
    show: {
      Boolean,
      default: false
    }
  }
}
