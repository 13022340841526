import { kBig } from '@/utils/kBig'

export const formatPhoneNumber = (digitString, blankFiller = '—') => {
  if (digitString) {
    const number = digitString.replace(/[^\d]/g, '')

    if (number.length === 10) {
      return number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
    }

    if (number.length === 11) {
      return number.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1-$2-$3-$4')
    }

    return digitString
  }
  return blankFiller
}

export const titlelize = function titlelize(str) {
  if (str === null || typeof str === 'undefined') {
    return str
  }

  if (str.includes(' ')) {
    let strParts = str.split(' ')
    let capitalizedParts = []
    for (let sp of strParts) {
      capitalizedParts.push(titlelize(sp))
    }
    return capitalizedParts.join(' ')
  }
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

/*
  moves decimal two places to the right. 0.78888 -> 78.89
*/
export const decimalToPercentage = (digitString, blankFiller = '—') => {
  if (digitString) {
    const percentage = kBig(digitString).times(100)
    return `${percentage.toFixed(1)}`
  }
  return blankFiller
}

/*
  format a float number to currency format / two decimal places without rounding
  so something like 0.0092 returns 0.00 not 0.01. (see specs for more example)

  @param number { Number | String }
  @returns { Number } float with two decimal places
*/
export const toFixedTwoNoRound = (number) => {
  if (number === 'NaN' || isNaN(number) || (typeof number === 'string' && number.length < 1)) {
    return ''
  }
  // https://stackoverflow.com/a/4187164
  const num = Number(number)
  const toFixedStr = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]

  return Number(toFixedStr)
}
