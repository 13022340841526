import store from '@/store'
const EmployeesPage = () => import('@/views/Merchant/Employees')

export default {
  path: '/employees',
  name: 'employees',
  component: EmployeesPage,
  meta: {
    title: 'Kafene | Employees',
    merchant: true
  },
  beforeEnter: (to, from, next) => {
    const isAuthorized = store.getters['events/getAuthorizationStatus']
    const hasMerchantAccess = store.getters['events/getMerchantAccess']
    if (hasMerchantAccess) {
      if (isAuthorized) {
        next()
      } else {
        store.dispatch('events/updateShowAuthorizationModal', true)
      }
    } else {
      next()
    }
  }
}
