const LeaseDetails = () => import('@/views/Merchant/Reports/LeaseDetails')

export default {
  path: '/reports/lease-details',
  name: 'lease-details',
  component: LeaseDetails,
  meta: {
    title: 'Kafene | Lease Details'
  }
}
