import KModal from '@/components/Modal'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'KEmployeeAccess',
  components: {
    KModal
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    secure: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isLoading: false,
    showError: false,
    otp_token: '',
    error: ''
  }),
  computed: {
    ...mapGetters({
      merchant: 'auth/getUser',
      merchantAccess: 'events/getMerchantAccess',
      merchantPhoneNumber: 'events/getMerchantPhoneNumber'
    }),
    lastFour() {
      const { merchantPhoneNumber } = this
      return merchantPhoneNumber && merchantPhoneNumber.substr(merchantPhoneNumber.length - 4)
    }
  },
  watch: {
    showModal: {
      handler(val) {
        if (val) {
          this.requestOTP()
        } else {
          this.isLoading = false
          this.showError = false
          this.otp_token = ''
        }
      },
      immediate: true
    },
    otp_token(val) {
      if (val && val.length >= 6) {
        this.showError = false
      }
    }
  },
  methods: {
    ...mapActions({
      requestMerchantOTP: 'events/requestMerchantOTP',
      verifyMerchantOTP: 'events/verifyMerchantOTP',
      updateAuthorizationStatus: 'events/updateAuthorizationStatus',
      updateAccessStatus: 'events/updateAccessStatus'
    }),
    async confirmOTP() {
      const { otp_token, merchant } = this
      if (otp_token && otp_token.length > 3) {
        this.isLoading = true
        this.error = ''
        this.showError = false
        try {
          const verified = await this.verifyMerchantOTP({
            email: merchant.email,
            otp_token,
            type: this.secure ? 'secure_access' : 'login'
          })
          if (verified) {
            this.$router.push(verified === 'disabled' ? '/' : '/team')
          }
        } catch (error) {
          this.isLoading = false
          this.showError = true
          if (error.includes('Please')) {
            this.error = 'This OTP has expired. Please request for a new one'
          }
        }
      } else {
        this.isLoading = false
        this.showError = true
      }
    },
    async requestOTP() {
      if (this.lastFour && this.lastFour.length) {
        try {
          const requested = await this.requestMerchantOTP({ email: this.merchant.email })
          if (requested) {
            this.$toast.open({
              message: `We have sent a single-use merchant access code to ***-***-${this.lastFour}.`,
              type: 'success',
              icon: 'checkmark',
              fade: 3000
            })
          }
        } catch (error) {
          this.$toast.open({
            message: `Error while sending single-use access code to ***-***-${this.lastFour}., please try again`,
            type: 'error',
            icon: 'close',
            fade: 3000
          })
        }
      }
    },
    closeModal() {
      this.$emit('close')
      this.updateAuthorizationStatus(false)
      this.$router.push('/')
    }
  }
}
