export default {
  name: 'KButton',
  props: {
    type: {
      type: String,
      default: 'button'
    },
    size: {
      type: String,
      default: 'regular'
    },
    color: {
      type: String,
      default: 'primary'
    },
    outline: {
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      default: 'click'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    link: {
      type: Boolean,
      default: false
    },
    full: {
      type: Boolean,
      default: false
    },
    qa: {
      type: String
    },
    underline: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String
    }
  },
  computed: {
    buttonClasses() {
      return [
        'btn',
        `btn__${this.size}`,
        `btn__${this.color}`,
        this.outline ? 'outline' : '',
        this.link ? 'btn__link' : '',
        this.full ? 'btn__full' : '',
        this.underline ? 'btn__underline' : ''
      ]
    }
  },
  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
