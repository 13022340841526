import { ValidationProvider } from 'vee-validate'
import KFrame from '@/components/Frame'
import { KTextInput } from '@/components/Input'
import '@/utils/validators'
export default {
  name: 'KSelect',
  components: {
    ValidationProvider,
    KFrame,
    KTextInput
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      default: ''
    },
    readonly: {
      type: Boolean
    },
    name: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: String,
      default: ''
    },
    customError: {
      type: String,
      default: ''
    },
    vid: {
      type: String,
      default: ''
    },
    small: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showError: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    showChild: {
      type: Boolean,
      default: false
    }
  },
  model: {
    event: 'change'
  },
  data: () => ({
    optionsVisible: false,
    valueString: '',
    filteredOptions: []
  }),
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(data) {
        if (data) {
          this.$emit('change', data)
        } else {
          this.$emit('change', '')
        }
      }
    },
    allValues() {
      const val = []
      if (this.options.length) {
        if (this.options[0].label) {
          this.options.map(({ label }) => val.push(label))
          return val
        } else {
          return this.options
        }
      } else {
        return ''
      }
    },
    valueExists() {
      const { valueString, allValues } = this
      return typeof valueString !== 'object' ? valueString && allValues.includes(valueString) : true
    }
  },
  watch: {
    localValue(val) {
      this.modifyValue(val)
    },
    options: {
      deep: true,
      immediate: true,
      handler(val) {
        this.filteredOptions = val
        this.modifyValue(this.localValue)
      }
    },
    valueExists(val) {
      if (!val) {
        this.localValue = ''
      }
    }
  },
  mounted() {
    this.filteredOptions = this.options
  },
  methods: {
    handleFocus(e) {
      // Fix IE11 quirks.
      if (e.target.tagName === 'DIV') return
      this.optionsVisible = true
    },
    handleOutsideFocus() {
      this.optionsVisible = false
      this.clearFilter()
    },
    handleIconClose() {
      if (this.optionsVisible) {
        this.optionsVisible = false
      } else {
        this.optionsVisible = true
      }
    },
    filterOptions() {
      if (this.valueString) {
        const search = String(this.valueString).toLowerCase()
        this.filteredOptions = this.options.filter((option) =>
          option.label
            ? String(option.label).toLowerCase().startsWith(search)
            : String(option).toLowerCase().startsWith(search)
        )
      } else {
        this.filteredOptions = this.options
        this.localValue = ''
      }
    },
    clearFilter() {
      setTimeout(() => {
        this.filteredOptions = this.options
        if (!this.valueExists || this.valueString == '') {
          this.valueString = ''
        } else {
          const val = typeof this.valueString === 'string' ? this.valueString.toLowerCase() : ''
          const selected = this.options.find(
            (option) => String(option?.label).toLowerCase() === val || String(option).toLowerCase() === val
          )
          this.localValue = (selected && selected.value) || selected
          this.valueString = (selected && selected.label) || selected
        }
      }, 300)
    },
    modifyValue(val) {
      if (val) {
        this.optionsVisible = false
        const selected = this.options.find((option) => option?.value === val || option === val)
        this.valueString = selected && selected?.label ? selected.label : selected
      } else {
        this.valueString = ''
      }
    }
  }
}
