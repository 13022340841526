import api from '@/utils/api'

export default {
  // eslint-disable-next-line
  sendProveAuthLink: ({ commit }, payload) => {
    try {
      const response = api.post('/prove/authLink', payload)
      return response
    } catch (error) {
      return error
    }
  },
  // eslint-disable-next-line
  getProveVerification: ({ commit }, sessionId) => {
    try {
      const response = api.get(`/prove/proveVerification/${sessionId}`)
      return response
    } catch (error) {
      return error
    }
  },
  // eslint-disable-next-line
  invalidateProveVerification: ({ commit }, sessionId) => {
    try {
      const response = api.post(`/prove/expireVerification/${sessionId}`)
      return response
    } catch (error) {
      return error
    }
  },
  // eslint-disable-next-line
  getUserPrefill: ({ commit }, payload) => {
    try {
      const response = api.post('/prove/pre-fill', payload)
      return response
    } catch (error) {
      return error
    }
  }
}
