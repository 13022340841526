import Vue from 'vue'
import VueRouter from 'vue-router'
import * as MerchantRoutes from './merchant'
import * as AuthRoutes from './auth'

Vue.use(VueRouter)

const routeKeys = [...Object.keys(MerchantRoutes), ...Object.keys(AuthRoutes)]

let routes = []

routeKeys.forEach((key) => routes.push(MerchantRoutes[key] || AuthRoutes[key]))

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: 'active',

  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes: routes
})

export default router
