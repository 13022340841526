import api from '@/utils/api'

export default {
  // eslint-disable-next-line
  setDeliveryDate: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      api
        .post('applications/schedule-delivery', payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  },

  markAsDelivered: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      api
        .post('applications/deliver', payload)
        .then(({ data }) => {
          dispatch('fetchSingleApplication', payload.application_id)
          resolve(data)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  },
  // eslint-disable-next-line
  getSummary: ({ dispatch }, applicationId) => {
    return new Promise((resolve, reject) => {
      api
        .get(`leases/${applicationId}/summary`, { responseType: 'blob' })
        .then((res) => {
          resolve(res.data)
        })
        .catch((ex) => {
          reject(ex)
        })
    })
  }
}
