const InputMixin = {
  props: {
    vid: {
      type: String,
      default: undefined
    },
    name: {
      type: String,
      default: ''
    },
    rules: {
      type: [Object, String],
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text',
      validator(value) {
        return ['url', 'text', 'password', 'tel', 'search', 'number', 'email'].includes(value)
      }
    },
    value: {
      type: null,
      default: ''
    },
    small: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean,
      default: false
    },
    iconFar: {
      type: Boolean,
      default: false
    },
    customError: {
      type: String,
      default: ''
    },
    warning: {
      type: String,
      default: null
    },
    showError: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    userlanguage: {
      type: String
    },
    componentId: {
      type: String,
      default: ''
    },
    qa: {
      type: String
    }
  },
  data: () => ({
    innerValue: ''
  }),
  computed: {
    hasValue() {
      return !!this.innerValue
    },
    id() {
      return (
        this.componentId ||
        (this.name && this.name.replace(/[^\w\s]|\s/gi, '')) ||
        (this.placeholder && this.placeholder.replace(/[^\w\s]|\s/gi, ''))
      )
    }
  },
  watch: {
    innerValue(value) {
      this.$emit('input', value)
      this.$emit('change', value)
    },
    value(val) {
      if (val !== this.innerValue) {
        this.innerValue = val
      }
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
export default InputMixin
