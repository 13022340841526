const ReportsPage = () => import('@/views/Merchant/Reports')

export default {
  path: '/reports/application-lease-activity',
  name: 'reports',
  component: ReportsPage,
  meta: {
    title: 'Kafene | App & Lease'
  }
}
