import api from '@/utils/api'

export default {
  cancelLease: ({ state, dispatch }) => {
    return new Promise((resolve, reject) => {
      const {
        currentApplication: {
          application: { id },
          lease_details_v2: { id: leaseId }
        }
      } = state
      api
        .post('/merchants/cancel-lease', { application_id: id, lease_id: leaseId })
        .then(({ data }) => {
          dispatch('fetchSingleApplication', id)
          resolve(data.data.message)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  },
  signRecontractedUserAgreement: ({ state, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      const {
        currentApplication: {
          application: { id }
        }
      } = state
      api
        .post('merchants/recontract', { application_id: id, ...payload })
        .then(({ data }) => {
          dispatch('fetchSingleApplication', id)
          resolve(data)
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.message) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  }
}
