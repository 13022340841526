import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  data: () => ({
    productTypeIsLoading: false
  }),
  provide() {
    // use function syntax so that we can access `this`
    return {
      productTypeIsLoading: this.productTypeIsLoading
    }
  },
  computed: {
    ...mapGetters({
      stores: 'stores/getStores',
      merchant: 'auth/getUser',
      productTypeIsLoan: 'stores/getProductTypeIsLoan'
    })
  },
  async created() {
    this.productTypeIsLoading = true

    await this.fetchStores({ merchant_id: this.merchant.merchant_id })

    let loans = this.stores.filter((x) => x?.product_type && x.product_type.includes('loan'))

    if (loans.length > 0) {
      this.updateProductTypeIsLoan(true)
    }

    return false
  },
  methods: {
    ...mapActions({
      fetchStores: 'stores/fetchStores'
    }),
    ...mapMutations({
      updateProductTypeIsLoan: 'stores/updateProductTypeIsLoan'
    })
  }
}
