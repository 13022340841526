import KFrame from '@/components/Frame'

export default {
  name: 'KReportsDropdown',
  components: {
    KFrame
  },
  data: () => ({
    optionsVisible: false
  }),
  methods: {
    handleFocus(e) {
      // Fix IE11 quirks
      if (e.target.tagName === 'DIV') return
      this.optionsVisible = true
    },
    handleOutsideFocus() {
      this.optionsVisible = false
    },
    goToRoute(name) {
      this.$router.push({ name })
      this.handleOutsideFocus()
      this.$emit('closeNav')
    }
  }
}
