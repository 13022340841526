import jwt_decode from 'jwt-decode'
import moment from 'moment'

export const verifyUserSession = (userToken) => {
  if (!userToken) return { userSessionHasExpired: true }
  const decoded = jwt_decode(userToken)
  const { exp: expiryTime } = decoded,
    currentTime = new Date().getTime() / 1000
  const userSessionHasExpired = moment(currentTime).isAfter(expiryTime)

  return { userSessionHasExpired }
}
