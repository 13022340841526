export default {
  getPricingData: (state) => state.pricingData,
  // Note: for SOP move 18m forward
  getPricingTerms: (state) =>
    state.sopPricing
      ? [
          state.pricingTerms.find(({ ownership_term }) => ownership_term === 18),
          ...state.pricingTerms.filter(({ ownership_term }) => ownership_term !== 18)
        ]
      : state.pricingTerms,
  isSop: (state) => state.sopPricing
}
