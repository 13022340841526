export default {
  updateStores: (state, payload) => {
    state.stores = [
      {
        id: 'all',
        name: '',
        location: 'All stores'
      },
      ...payload
    ]
  },
  updateSelectedStores: (state, payload = 'all') => {
    state.selectedStore = state.stores.find((store) => store.id === payload)
  },
  updateEmployees: (state, payload) => {
    state.employees = payload
  },
  updateProductTypeIsLoan: (state, payload) => {
    state.productTypeIsLoan = payload
  }
}
