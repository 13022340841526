import api from '@/utils/api'

export default {
  cancelApplication: ({ state }, payload) => {
    return new Promise((resolve, reject) => {
      const {
        currentApplication: {
          application: { id }
        }
      } = state

      api
        .put('applications/cancel-application', { application_id: payload.application_id || id })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  }
}
