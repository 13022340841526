import store from '@/store'
const TeamsPage = () => import('@/views/Merchant/Team')

export default {
  path: '/team',
  name: 'team',
  component: TeamsPage,
  meta: {
    title: 'Kafene | Team',
    merchant: true
  },
  beforeEnter: (to, from, next) => {
    const isAuthorized = store.getters['events/getAuthorizationStatus']
    const hasMerchantAccess = store.getters['events/getMerchantAccess']
    if (hasMerchantAccess) {
      if (isAuthorized) {
        next()
      } else {
        store.dispatch('events/updateShowAuthorizationModal', true)
      }
    } else {
      next()
    }
  }
}
