const W9 = () => import(/* webpackChunkName: "w9" */ '@/views/W9/W9')

export default {
  path: '/w9/:id',
  name: 'w9',
  component: W9,
  meta: {
    auth: true,
    password: true
  }
}
