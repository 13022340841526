import KModal from '@/components/Modal'

export default {
  name: 'KLoggedOutModal',
  components: { KModal },
  props: {
    show: {
      Boolean,
      default: false
    },
    isLoggedIn: {
      Boolean,
      default: false
    },
    isUserSessionExpired: {
      Boolean,
      default: false
    }
  },
  computed: {
    title() {
      let title = 'You have been logged out.'
      if (this.isLoggedIn && this.isUserSessionExpired) {
        title += ' Session expired.'
      }
      return title
    },
    description() {
      if (this.isLoggedIn && this.isUserSessionExpired) {
        return 'Please log back in using your credentials.'
      }
      return 'Please click the button below to log back in.'
    }
  },
  methods: {
    async logout() {
      await this.$store.dispatch('auth/logout')
      await this.$store.dispatch('events/updateShowLogoutModal', false)
      await this.$router.push({ name: 'login' })
    }
  }
}
