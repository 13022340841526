import api from '@/utils/api'

export default {
  createTextToApplyLease: ({ dispatch }, data) => {
    return new Promise((resolve, reject) => {
      const { application_id } = data
      api
        .post('/applications/create-lease', { application_id })
        .then(({ data }) => {
          dispatch('fetchSingleApplication', application_id)
          resolve(data.data.message)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  }
}
