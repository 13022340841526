import { Money } from 'v-money'
import KInputWrapper from '../InputWrapper'
import InputMixin from '@/mixins/input'
export default {
  name: 'KMoneyInput',
  components: {
    Money,
    KInputWrapper
  },
  mixins: [InputMixin],
  data: () => ({
    money: {
      decimal: '.',
      thousands: ',',
      prefix: '$ ',
      precision: 2,
      masked: false
    },
    touched: false
  }),
  props: {
    negative: {
      type: Boolean,
      default: false
    },
    noDecimals: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    const { value, placeholder } = this
    if (value) {
      this.innerValue = Number(value).toFixed(2)
      this.$refs[placeholder].$el.value = `$ ${Number(value).toFixed(2)}`
    }

    if (this.noDecimals) {
      this.$set(this.money, 'precision', 0)
    }
  },
  watch: {
    value(val) {
      const { placeholder } = this
      if (val !== this.innerValue && !this.touched) {
        this.innerValue = Number(val).toFixed(2)
        this.$refs[placeholder].$el.value = `$ ${Number(val).toFixed(2)}`
      }
    },
    touched(val) {
      if (val) {
        setTimeout(() => {
          this.$refs.parent.$children[0].$children[0].$el.focus()
        }, 200)
      }
    }
  }
}
