export default {
  stores: [
    {
      id: 'all',
      name: '',
      location: 'All stores'
    }
  ],
  selectedStore: {
    id: 'all',
    name: '',
    location: 'All stores'
  },
  employees: [],
  productTypeIsLoan: false
}
