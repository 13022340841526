import api from '@/utils/api'

export default {
  fetchPricingData: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      api
        .post('applications/get-potential-pricing', data)
        .then(({ data }) => {
          const {
            pricing_data: pricingData,
            calculated_pricing_terms: pricingTerms,
            showSOPPricing = false
          } = data.data
          commit('updatePricingData', pricingData)
          commit('updatePricingTerms', pricingTerms)
          commit('updateSopPricing', showSOPPricing)
          resolve()
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  }
}
