export default {
  SET_UPDATE_LOGOUT_MODAL: (state, payload) => {
    state.showLogoutModal = payload
  },
  SET_UPDATE_AUTHORIZATION_MODAL: (state, payload) => {
    state.showAuthorizationModal = payload
  },
  SET_UPDATE_AUTHORIZATION_STATUS: (state, payload) => {
    state.isAuthorized = payload
  },
  SET_UPDATE_ACCESS_STATUS: (state, payload) => {
    state.merchantAccess = payload
  },
  SET_UPDATE_PHONE_NUMBER: (state, payload) => {
    state.merchantPhoneNumber = payload
  },
  SET_SELECTED_ROW_DROPDOWN_STATE: (state, payload) => {
    state.evtSelectRowDropDownStateValue = payload
  },
  SET_SURVICATE_DETAILS: () => {
    // Info: it triggers survicate plugin with payload data
  }
}
