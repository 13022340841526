import KToastList from '@/components/ToastList'
import EventBus from '@/utils/event-bus'

export default {
  install(Vue) {
    Vue.component(KToastList.name, KToastList)
    const toast = {
      push(options) {
        EventBus.$emit('showToastNotificationListAdd', options)
      }
    }
    Vue.prototype.$toastList = toast
  }
}
