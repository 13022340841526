import { initialize } from 'launchdarkly-js-client-sdk'

export const launchDarklyPlugin = (launchDarklyApiKey) => (store) => {
  store.registerModule('features', {
    namespaced: true,
    state: {
      ldClient: null,
      initialized: false,
      flags: {}
    },
    mutations: {
      SET_CLIENT(state, client) {
        state.ldClient = client
      },
      SET_INITIALIZED(state, value) {
        state.initialized = value
      },
      SET_FLAGS(state, flags) {
        state.flags = flags
      }
    },
    actions: {
      async init({ commit }, merchant) {
        const multiContext = {
          kind: 'multi',
          merchant: {
            key: merchant.merchant_id,
            email: merchant.email,
            first_name: merchant.first_name,
            last_name: merchant.last_name,
            id: merchant.id,
            name: merchant.name,
            super_admin: merchant.super_admin,
            user_role: merchant.user_role
          }
        }

        const client = initialize(launchDarklyApiKey, multiContext)

        client.on('initialized', () => {
          const flags = client.allFlags() || {}
          commit('SET_FLAGS', flags)
          commit('SET_INITIALIZED', true)
        })

        client.on('change', async () => {
          commit('SET_FLAGS', client.allFlags() || {})
        })

        await client.waitForInitialization(5)
        commit('SET_CLIENT', client)
      },
      async attachContext({ state, commit }, newContext) {
        const client = state.ldClient
        if (!client) return

        const currentContext = client.getContext()
        if (!currentContext) return

        const { kind, context } = newContext
        currentContext[kind] = context

        try {
          const flags = await client.identify(currentContext)
          if (flags) {
            commit('SET_FLAGS', flags)
          }
        } catch (error) {
          console.error('Error identifying LaunchDarkly client:', error)
        }
      }
    },
    getters: {
      isInitialized: (state) => state.initialized,
      getFlag: (state) => (flagKey, defaultValue) => {
        return state.flags[flagKey] || defaultValue || false
      }
    }
  })
}
