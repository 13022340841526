export default {
  updatePricingData: (state, payload) => {
    state.pricingData = payload
  },
  updatePricingTerms: (state, payload) => {
    state.pricingTerms = payload
  },
  updateSopPricing: (state, payload) => {
    state.sopPricing = payload
  }
}
