import api from '@/utils/api'

export default {
  // eslint-disable-next-line
  applyWithCoApplicant: ({ commit }, payload) => {
    try {
      const response = api.post('/co-applicant/apply', payload)
      return response
    } catch (error) {
      return error
    }
  },

  // eslint-disable-next-line
  updateDeniedAppWithCoApplicantAppId: ({ commit }, payload) => {
    try {
      const response = api.patch('/co-applicant/update', payload)
      return response
    } catch (error) {
      return error
    }
  }
}
