import Big from 'big.js'

/**
 * BIG.JS INSTANTIATION WRAPPER - use with floating point calculations
 * This is so we can avoid inaccurate edge cases with JS math:
 * 0.2 * 0.1 = 0.020000000000000004
 * 1500.01 - 1500.0000 = 0.009999999999990905
 * 12.2 / 0.1 = 121.99999999999999
 * 0.2 * 0.1 === 0.3 will return false.
 *
 * @param {(Number|String)} number
 * @returns {Object} a Big object.
 *      To get value, call one of the .to__ methods (.toFixed, .toString, .valueOf)
 *      [http://mikemcl.github.io/big.js/#prototype-methods]
 */
export function kBig(number) {
  // turn value to 0, in case value from db returns null or undefined
  if (number === null || typeof number === 'undefined') {
    number = 0
  }

  return new Big(number)
}
