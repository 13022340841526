const NewApplication = () => import(/* webpackChunkName: "applications" */ '@/views/Merchant/Applications/New')

export default {
  path: '/applications/new',
  name: 'new-application',
  component: NewApplication,
  meta: {
    title: 'Kafene | New Application',
    showAutofill: true
  }
}
