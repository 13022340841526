import api from '@/utils/api'

export default {
  selectLeaseTerm: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      api
        .post('applications/add-lease-length', payload)
        .then(({ data }) => {
          dispatch('fetchSingleApplication', payload.application_id)
          resolve(data)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  }
}
