import KIcon from '@/components/Icon'
import EventBus from '@/utils/event-bus'

export default {
  name: 'KToast',
  components: {
    KIcon
  },
  data: () => ({
    showToast: false,
    message: '',
    type: 'success',
    icon: null,
    html: false
  }),
  created() {
    EventBus.$on('showToastNotification', (options) => {
      this.showToast = true
      const { message, fade, type, icon, html } = options
      this.message = message || 'An error occured while performing action'
      this.type = type ? type : this.type
      this.icon = icon ? icon : this.icon
      this.html = html ? true : false
      window.FS.event('Toasted', {
        message,
        type,
        icon
      })
      if (fade) {
        setTimeout(() => {
          this.removeToast()
        }, fade)
      }
    })
    EventBus.$on('closeToastNotification', () => {
      this.removeToast()
    })
  },
  methods: {
    removeToast() {
      this.showToast = false
      this.message = ''
      this.icon = null
      this.html = false
    }
  }
}
