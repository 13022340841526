import { ValidationProvider } from 'vee-validate'
import InputMixin from '@/mixins/input'
import '@/utils/validators'
export default {
  name: 'KInputWrapper',
  components: {
    ValidationProvider
  },
  mixins: [InputMixin]
}
