import fetchApplications from './fetch'
import createInvoice from './create-invoice'
import selectTerm from './select-lease-term'
import agreement from './agreement'
import application from './application'
import delivery from './delivery'
import create from './create'
import fetchStates from './states'
import recontract from './recontract'
import reset from './reset'
import validateZipcode from './validate-zipcode'
import fetchResaleCertificates from './resale-certificates'
import textToApply from './text-to-apply'
import verifyPhoneNumber from './verifyPhoneNumber'
import canUserExtendEpo from './epoExtension'
import getPotentialEpoPlans from './epoExtension'
import prove from './prove-verification'
import coapplicant from './co-applicant'
import higherAA from './higher-aa'

export default {
  ...fetchApplications,
  ...fetchStates,
  ...createInvoice,
  ...validateZipcode,
  ...recontract,
  ...selectTerm,
  ...agreement,
  ...delivery,
  ...create,
  ...application,
  ...reset,
  ...fetchResaleCertificates,
  ...textToApply,
  ...verifyPhoneNumber,
  ...canUserExtendEpo,
  ...getPotentialEpoPlans,
  ...prove,
  ...coapplicant,
  ...higherAA
}
