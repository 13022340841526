import api from '@/utils/api'

export default {
  // eslint-disable-next-line
  validateZipcode: ({ state }, data) => {
    return new Promise((resolve, reject) => {
      api
        .post('applications/verify-zipcode', data)
        .then(({ data }) => {
          resolve(data.data.data)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  }
}
