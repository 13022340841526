import { TheMask } from 'vue-the-mask'
import KInputWrapper from '../InputWrapper'
import InputMixin from '@/mixins/input'
export default {
  name: 'KTextInput',
  components: {
    KInputWrapper,
    TheMask
  },
  mixins: [InputMixin],
  props: {
    inputRef: {
      type: String,
      default: ''
    },
    mask: {
      type: String,
      default: ''
    },
    inputmode: {
      type: String,
      default: 'text'
    },
    qa: {
      type: String
    }
  },
  methods: {
    noSpace($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which
      if (this.type === 'email') {
        if (keyCode === 32) {
          // 32 is space bar
          $event.preventDefault()
        }
      }
    }
  }
}
