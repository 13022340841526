const DigitalBanners = () => import(/* webpackChunkName: "resources" */ '@/views/Merchant/Resources/DigitalBanners')

export default {
  path: '/resources/digital-banners',
  name: 'digital-banners',
  component: DigitalBanners,
  meta: {
    title: 'Kafene | Banners'
  }
}
