import KFrame from '@/components/Frame'
import { isLearning, learningModeHostname, normalModeHostname } from '@/utils/learning-platform'

export default {
  name: 'KResourcesDropdown',
  components: {
    KFrame
  },
  data: () => ({
    optionsVisible: false,
    showLearningModal: false
  }),
  computed: {
    learningUrl() {
      return `//${learningModeHostname}`
    },
    learningDebug() {
      return {
        isLearning,
        learningModeHostname,
        normalModeHostname,
        node_env: process.env.VUE_APP_NODE_ENV
      }
    },
    learningDebugTypes() {
      return {
        isLearning: typeof isLearning,
        learningModeHostname: typeof learningModeHostname,
        normalModeHostname: typeof normalModeHostname,
        node_env: typeof process.env.VUE_APP_NODE_ENV
      }
    },
    showLearning() {
      return !isLearning
    }
  },
  methods: {
    openLearning() {
      window.open(this.learningUrl)
    },
    handleFocus(e) {
      // Fix IE11 quirks -
      if (e.target.tagName === 'DIV') return
      this.optionsVisible = true
    },
    handleOutsideFocus() {
      this.optionsVisible = false
    },
    openLearningModal() {
      this.showLearningModal = true
      this.handleOutsideFocus()
      this.$emit('closeNav')
    },
    closeLearningModal() {
      this.showLearningModal = false
    },
    goToRoute(name) {
      this.$router.push({ name })
      this.handleOutsideFocus()
      this.$emit('closeNav')
    }
  }
}
