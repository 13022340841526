import api from '@/utils/api'

export default {
  // eslint-disable-next-line
  applyWithHigherAA: ({ commit }, payload) => {
    try {
      const response = api.patch('higher-aa/apply', payload)
      return response
    } catch (error) {
      throw new Error(error.response.data.message)
    }
  },
  // eslint-disable-next-line
  removeHigherAAExperience: ({ commit }, payload) => {
    try {
      return api.patch(`/higher-aa/`, payload)
    } catch (error) {
      throw new Error(error.response.data.message)
    }
  }
}
