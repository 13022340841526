const Documents = () => import(/* webpackChunkName: "resources" */ '@/views/Merchant/Resources/Documents')

export default {
  path: '/resources/documents',
  name: 'documents',
  component: Documents,
  meta: {
    title: 'Kafene | Documents'
  }
}
