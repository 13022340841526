import { ValidationObserver } from 'vee-validate'
import debounce from 'lodash.debounce'
import { mapActions, mapGetters } from 'vuex'
import KModal from '@/components/Modal'
import KSelect from '@/components/Select'

export default {
  name: 'KCalculator',
  components: {
    KModal,
    KSelect,
    ValidationObserver
  },
  props: {
    show: {
      Boolean,
      default: false
    }
  },
  async mounted() {
    const { isLoggedIn } = this
    if (isLoggedIn) {
      this.stateFetch()
    }
  },
  data: () => ({
    lease_amount: '',
    tier_id: '',
    state: '',
    payment_frequency: '',
    isLoading: false,
    pricingTiers: [],
    states: [],
    estimates: {},
    frequencies: [
      { label: 'Weekly', value: 'weekly' },
      { label: 'Every Other Week', value: 'bi-weekly' },
      { label: 'Twice a Month', value: 'bi-monthly' }
    ]
  }),
  computed: {
    ...mapGetters({
      merchant: 'auth/getUser',
      isLoggedIn: 'auth/getAuthState'
    })
  },
  methods: {
    ...mapActions({
      fetchStates: 'calculator/fetchStates',
      fetchPricingTiers: 'calculator/fetchPricingTiers',
      calculateEstimate: 'calculator/calculateEstimate'
    }),
    async stateFetch() {
      const {
        merchant: { merchant_id }
      } = this
      try {
        const states = await this.fetchStates({ merchant_id })
        const pricingTiers = await this.fetchPricingTiers()
        if (states && pricingTiers) {
          let tiers = []
          let availableStates = []
          pricingTiers.map((tier) => {
            tiers.push({
              label: tier.name,
              value: tier.id
            })
          })
          states.map((state) => {
            availableStates.push({
              label: state.stateName,
              value: state.stateCode
            })
          })
          this.pricingTiers = tiers
          this.states = availableStates.sort((a, b) => {
            if (a.label < b.label) return -1
            if (a.label > b.label) return 1
            return 0
          })
          this.reset()
        }
      } catch (error) {
        this.$toast.open({
          message: error || 'Error while fetching data',
          type: 'error',
          icon: 'close',
          fade: 3000
        })
      }
    },
    onSubmit: debounce(async function () {
      const { lease_amount, tier_id, payment_frequency, state } = this
      if (this.$refs.form) {
        const formValid = await this.$refs.form.validate()
        if (lease_amount > 0 && tier_id && payment_frequency && state && formValid) {
          this.isLoading = true
          try {
            const calculatedAmount = await this.calculateEstimate({
              lease_amount: lease_amount.toString(),
              tier_id,
              payment_frequency,
              state
            })
            if (calculatedAmount) {
              this.estimates = calculatedAmount
              this.isLoading = false
            }
          } catch (error) {
            this.isLoading = false
            this.$toast.open({
              message: error || 'Error while fetching pricing data',
              type: 'error',
              icon: 'close',
              fade: 3000
            })
          }
        }
      }
    }, 300),
    reset() {
      this.tier_id = this.pricingTiers.find((tier) => tier.label === 'Plus')?.value || ''
      this.state = (this.states && this.states[0]?.value) || ''
      this.payment_frequency = 'bi-monthly'
      this.lease_amount = ''
      this.estimates = {}

      if (this.$refs.form) {
        this.$refs.form.reset()
      }

      this.isLoading = false
    },
    closeModal() {
      this.reset()
      this.$emit('close')
    }
  },
  watch: {
    show() {
      this.estimates = {}
    },
    isLoggedIn(val) {
      if (val) {
        this.stateFetch()
      }
    }
  }
}
