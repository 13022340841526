import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import KInputWrapper from '../InputWrapper'
import InputMixin from '@/mixins/input'
import { TheMask } from 'vue-the-mask'
import moment from 'moment'
import '@/utils/validators'
export default {
  name: 'KDateInput',
  components: {
    KInputWrapper,
    DatePicker,
    TheMask
  },
  mixins: [InputMixin],
  data: () => ({
    date: null,
    isFocused: false,
    pickerVisibility: { visibility: 'focus' }
  }),
  props: {
    minDate: {
      required: false,
      default: null
    },
    maxDate: {
      required: false,
      default: null
    },
    dateMode: {
      required: false, // options 'range' for date range
      default: null
    }
  },
  watch: {
    innerValue(value) {
      if (moment(value).isValid() && moment(value).year() > 1900) {
        this.$emit('input', value)
        this.$emit('change', value)
      } else {
        this.innerValue = ''
      }
    }
  },
  methods: {
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which
      if (((keyCode < 47 || keyCode > 57) && keyCode !== 191) || keyCode === 173) {
        $event.preventDefault()
      }
    },
    focusInput() {
      setTimeout(() => {
        this.$refs.input.focus()
      }, 100)
    }
  }
}
